/**
 * ? Package entry point
 * @package Name
 * 
 */

import Rellax from 'rellax';
const rellax = new Rellax('.rellax');
import './includes/fieldController'
import './includes/menuController'

import "core-js/stable";
import "regenerator-runtime";
import React from 'react';
//import ReactDOM from 'react-dom';
import ImageSection from './Components/vgb.imageSection';


const _ROOT = document.getElementById('vgb-imageSection');
    import("react-dom").then(ReactDOM => {
    if (_ROOT){
        ReactDOM.render(
            <ImageSection />,
            _ROOT
        );
    }
});

const imageSection = document.getElementById("vgb-imageSection--noReact");

const sectionAnimator = (target) => {

    const header = target.querySelector('h4');
    const content = target.querySelector('.vgb-imageSection__content');

    target.addEventListener('mouseenter', e => {
        mouseEnter(header, content);
    })

    target.addEventListener('mouseleave', e => {
        mouseLeave(header, content);
    })

    const mouseEnter = (header, content) => {
        const travelAmmount = (content.offsetHeight - (content.offsetHeight / 4));
        if (window.innerWidth >= 768)
            header.style.transform = `translateY(-${travelAmmount}px) translateX(-50%)`;
    }

    const mouseLeave = (header, content) => {    
        header.style.transform = `translateY(0px) translateX(-50%)`;
    }
}

if (imageSection){
    const elements = document.getElementsByClassName('vgb-imageSection__component');
    [].forEach.call(elements, (item) => {
        sectionAnimator(item);
    })
}

import GalleryController from './includes/GalleryController';

const galleryContainers = document.getElementsByClassName('blocks-gallery-grid');

[].forEach.call(galleryContainers, gallery => {    
    const galleryContainer = new GalleryController(gallery);
})

// Responsive hero section
const heroSection = document.querySelector('.heroSection__video');
const heroSectionBox = document.querySelector('.heroSection__extra');

const scaleHeroSection = () => {
    if (window.innerWidth >= 768) {
        heroSection.style.height = "";
        return;
    }

    //heroSection.style.height = `${(window.innerHeight - heroSectionBox.offsetHeight)}px`;
}

if (heroSection) {
    scaleHeroSection();
    window.addEventListener('resize', scaleHeroSection);
}