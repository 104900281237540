import React from 'react';
import ReactDOM from 'react-dom';
import ImageComponent from './vgb.imageComponent'


export default class ImageSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url : "/wp-json/acf/v3/pages/",
            data: {},
        }
    }

    async componentDidMount() {   
        let frontPageResult = await fetch('/wp-json/wpse/v1/frontpage');
        let frontPageJson = await frontPageResult.json();
        let frontPageID = await frontPageJson.ID;

        this.setState({url : `/wp-json/acf/v3/pages/${frontPageID}/`})

        let response = await fetch(this.state.url);
        let json = await response.json();     

        console.log(json);
        
        this.setState({ data: json.acf.section });   
        
        //this.setState({ data: json[0].acf.section });        
    }

    async componentDidUpdate() {
        if (this.state.data.hasOwnProperty('acf')) {
          let response = await fetch(this.state.url);
          let json = await response.json();

          this.setState({ data: json.acf.section });   
        }
    }

    render() {
        return(
            <div className="vgb-imageSection__inner">
               {Object.entries(this.state.data).map(([key, value]) => (
                   <ImageComponent 
                        key={key} 
                        src={value.img.sizes.imageComponent} 
                        title={value.title} 
                        text={value.text} 
                        link={value.href} 
                        id={key}
                    />
               ))}
            </div>
        );
    }
}